import React, { useMemo, useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
  createStyles,
  TableFooter,
  TablePagination,
} from '@material-ui/core'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import {
  ThemedHeaderCell,
  ThemedTableRow,
  ThemedCell,
  ThemedSortLabel,
} from 'components/common'
import { formatDate } from 'utils/formatDate'
import materialTheme from 'constants/theme/material'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableFooter: {
    borderBottom: 0,
  },

  head: {
    background: '#dee7e4',
  },
})

interface ITicketsTable {
  tickets: Api.ITicket[]
  loading: boolean
  onRowClick?: (ticket: Api.ITicket) => void
}

interface IHeadCell {
  disablePadding: boolean
  id: keyof Api.ITicket
  label: string
  numeric: boolean
  width?: string
}

type Order = 'asc' | 'desc'

const headCells: IHeadCell[] = [
  {
    id: 'otipID',
    numeric: false,
    disablePadding: true,
    label: 'OTIP ID',
    width: '10%',
  },
  {
    id: 'plan',
    numeric: false,
    disablePadding: false,
    label: 'Plan',
    width: '5%',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Patient First Name',
    width: '15%',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Patient Last Name',
    width: '15%',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Patient Address',
    width: '10%',
  },
  {
    id: 'memberFirstName',
    numeric: false,
    disablePadding: false,
    label: 'Member First Name',
    width: '15%',
  },
  {
    id: 'memberLastName',
    numeric: false,
    disablePadding: false,
    label: 'Member Last Name',
    width: '15%',
  },
  {
    id: 'requestedDrugName',
    numeric: false,
    disablePadding: false,
    label: 'Requested Drug Name',
    width: '10%',
  },
  {
    id: 'claimReceived',
    numeric: false,
    disablePadding: false,
    label: 'Claim Received',
    width: '10%',
  },
]

const TicketsTable = ({ tickets, loading, onRowClick }: ITicketsTable) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Api.ITicket>('lastName')

  const paginatedTickets = useMemo(() => {
    return rowsPerPage > 0
      ? stableSort(tickets, getSorting(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tickets
  }, [tickets, page, rowsPerPage, order, orderBy])

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tickets.length - page * rowsPerPage)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (e: any, ticket: Api.ITicket) => {
    e.preventDefault()

    if (onRowClick) {
      onRowClick(ticket)
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Api.ITicket
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <MuiThemeProvider theme={materialTheme}>
      <TableContainer>
        <Table className={classes.table} aria-label="Tickets table">
          <TableHead className={classes.head}>
            <TableRow>
              {headCells.map(cell => {
                return (
                  <ThemedHeaderCell
                    key={cell.id}
                    align="left"
                    sortDirection={orderBy === cell.id ? order : false}
                    style={{ width: cell.width }}
                  >
                    <ThemedSortLabel
                      hideSortIcon={true}
                      active={orderBy === cell.id}
                      direction={order}
                      onClick={event => handleRequestSort(event, cell.id)}
                    >
                      {cell.label}
                      {orderBy === cell.id ? (
                        <span
                          style={{
                            border: 0,
                            clip: 'rect(0 0 0 0)',
                            height: 1,
                            margin: -1,
                            overflow: 'hidden',
                            padding: 0,
                            position: 'absolute',
                            top: 20,
                            width: 1,
                          }}
                        >
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </ThemedSortLabel>
                  </ThemedHeaderCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <PlaceHolderRow />}
            {!loading && tickets.length === 0 && <NoResultsRow />}
            {!loading &&
              paginatedTickets.map((ticket: Api.ITicket) => (
                <ThemedTableRow
                  key={ticket.id}
                  onClick={event => handleClick(event, ticket)}
                >
                  <ThemedCell align="left">{ticket.certificate_number}</ThemedCell>
                  <ThemedCell align="left">{ticket.plan_sponsor}</ThemedCell>
                  <ThemedCell align="left">{ticket.patient_first_name}</ThemedCell>
                  <ThemedCell align="left">{ticket.patient_last_name}</ThemedCell>
                  <ThemedCell align="left">{ticket.patient_addresses[0]?.string}</ThemedCell>
                  <ThemedCell>{ticket.plan_member_first_name}</ThemedCell>
                  <ThemedCell align="left">{ticket.plan_member_last_name}</ThemedCell>
                  <ThemedCell align="left">
                    {ticket.requested_drug_name}
                  </ThemedCell>
                  <ThemedCell align="left">
                    {formatDate(ticket.date_created)}
                  </ThemedCell>
                </ThemedTableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledPagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={9}
                count={tickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  )
}

const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 0,
    },
  })
)(TablePagination)

const PlaceHolderRow = () => {
  return (
    <TableRow key="placeholder">
      {new Array(9).fill(null).map((item, index) => (
        <TableCell key={index} component="th" scope="row">
          <Skeleton variant="text" />
        </TableCell>
      ))}
    </TableRow>
  )
}

const NoResultsRow = () => {
  return (
    <TableRow key="noResults">
      <TableCell component="th" colSpan={9} scope="row">
        No results found
      </TableCell>
    </TableRow>
  )
}

/**
 * Sorting method
 * @param a previous element
 * @param b next element
 * @param orderBy key of the element
 */
function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

/**
 * Improved sorting
 * @param array array of element to sort
 * @param cmp sorting method
 */
function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]) // preserver default order

  // Sort with passed sorting method
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1] // keep default order if same
  })
  return stabilizedThis.map(el => el[0]) // return  array of elements only
}

/**
 * Returns proper desc method based on order and orderBy
 * @param order
 * @param orderBy
 */
function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

export { TicketsTable }
