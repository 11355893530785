/** @jsx jsx */
import MainLayout from 'components/layouts/main'
import { TicketsTable } from 'components/patientSearch/table'
import { PageTitle } from 'components/page-title'
import { ThemedPaper } from 'components/paper'
import SEO from 'components/seo'
import { navigate } from 'gatsby'
import { useMemo } from 'react'
import { Container, jsx } from 'theme-ui'
import { csrApi } from 'utils/api'
import { useApi } from 'utils/useApi'

const Patients = props => {
  const filter = useMemo(() => {
    return props.location.state?.inputs
  }, [
    props.location.state?.inputs.patient_first_name,
    props.location.state?.inputs.patient_last_name,
  ])

  const [tickets, loading] = useApi<Api.ITicket[]>(
    csrApi.getTickets,
    [],
    filter
  )

  /**
   * Handle row selection
   * @param ticket
   */
  const handleRowClick = (ticket: Api.ITicket) => {
    navigate(`/dashboard/patients/${ticket.id}`, { state: { ticket } })
  }

  return (
    <MainLayout>
      <SEO title="Dashboard | Patients" />
      <Container
        sx={{
          mt: -9,
          maxWidth: 1350,
          padding: 0,
          mb: 9,
        }}
      >
        <ThemedPaper>
          <PageTitle title="Search Results" backBtnTitle="Back to search" />
          <TicketsTable
            tickets={tickets}
            loading={loading}
            onRowClick={handleRowClick}
          />
        </ThemedPaper>
      </Container>
    </MainLayout>
  )
}

export default Patients
